import React from 'react';
import Layout from '@components/Layout/Layout';
import TextHero from '@components/TextHero';
import Grid, { Row } from '@components/Grid';
import Card, { CardImage } from '@components/Card';
import Container from '@components/Container';
import BottomCta from '@components/Home/BottomCta';
import { graphql } from 'gatsby';
import {
  BetalaQuery,
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulTextList,
} from '../../graphql-types';

import SEO from '@components/seo';
import useContentfulImage from '../hooks/useContentfulImage.hook';
import useDialogRoute from '@components/Dialog/useDialogRoute.hook';
import appenPages from '@src/data/appenPages';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';
import Illustration from '@src/components/Illustration/Illustration';
import ProductHero from '@src/components/ProductHero';
import SnapScrollContainer from '@src/components/SnapScrollContainer';
import Footer from '@src/components/Footer/Footer';
import StickyFooter from '@src/components/StickyFooter/StickyFooter';
import * as styles from './betala.module.scss';
import AppRatings from '@src/components/Home/AppRatings';
import useIsMobile from '../hooks/useIsMobile.hook';
import { Download } from '@mui/icons-material';
import DownloadAppButton from '@src/components/DownloadAppButton';
import UspList, { UspListItem } from '@src/components/UspList/UspList';

type BetalaProps = {
  data: BetalaQuery;
};

function Betala({ data }: BetalaProps) {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      heroImage,
      pageContent,
    },
  } = data;

  const { openDialog, closeDialog, isOpen: dialogVisible } = useDialogRoute(
    'kortinfo',
  );

  const isMobile = useIsMobile();

  const [card1] = pageContent as Array<ContentfulCard>;
  //const card1Image = useContentfulImage(card1.backgroundImage);
  //card1Image.objectPosition = '50% 13%';
  const dialogContent = pageContent[1] as ContentfulDialogContent;

  const cashbackDlg = useDialogRoute('tjana');
  const card2 = pageContent[2] as ContentfulCard;
  const dialogContent2 = pageContent[3] as ContentfulDialogContent;
  const card2Image = useContentfulImage(card2.backgroundImage);

  const touchDialog = useDialogRoute('touch');
  const touchDialogData = pageContent[4] as ContentfulDialogContent;

  const splitCard = pageContent[5] as ContentfulCard;
  const splitDialog = useDialogRoute('split');
  const splitDialogData = pageContent[6] as ContentfulDialogContent;
  const splitImg = useContentfulImage(splitCard.backgroundImage);

  const moneyCard = pageContent[7] as ContentfulCard;
  const moneyDialog = useDialogRoute('card');
  const moneyDialogData = pageContent[8] as ContentfulDialogContent;
  const moneyImg = useContentfulImage(moneyCard.backgroundImage);
  const heroCard = pageContent[9] as ContentfulCard;
  const heroImg = useContentfulImage(heroImage);
  const uspList = (pageContent[10] as ContentfulTextList).advancedTextListItems;

  return (
    <SnapScrollContainer>
      <Layout theme="white" subNavLinks={appenPages} customFooter={<></>}>
        <SEO title={headLine} description={pageHeaderDescription} />
        <Container>
          <ProductHero
            title="Betala med mobilen."
            subtitle="Eller tummen."
            image={heroImg}
          >
            <UspList items={uspList as UspListItem[]} />
            <DownloadAppButton />
            {!isMobile && <AppRatings />}
          </ProductHero>

          <Grid spacedRows>
            <Card
              title={card1.title}
              text={card1.subtitle}
              cta={card1.linkText}
              onClick={openDialog}
              //backdropImage={card1Image}
              theme="soft"
              noBorder
              snap={true}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Illustration
                  name="PaymentBadges"
                  block
                  size="80%"
                  style={{
                    marginTop: '10%',
                  }}
                />
              </div>
            </Card>
            <Card
              title={card2.title}
              backdropImage={card2Image}
              text={card2.subtitle}
              cta={card2.linkText}
              onClick={cashbackDlg.openDialog}
              noBorder
              theme="dark"
              snap={true}
            />

            <Row>
              <Card
                title={heroCard.title}
                text={heroCard.subtitle}
                contentfulImg={useContentfulImage(heroCard.backgroundImage)}
                theme="purple"
                cta={heroCard.linkText}
                onClick={touchDialog.openDialog}
                wide
                snap={true}
              />
            </Row>

            <Card
              title={splitCard.title}
              text={splitCard.subtitle}
              cta={splitCard.linkText}
              onClick={splitDialog.openDialog}
              snap={true}
            >
              <CardImage imageData={splitImg} />
            </Card>

            <Card
              title={moneyCard.title}
              text={moneyCard.subtitle}
              cta={moneyCard.linkText}
              theme="soft"
              noBorder
              onClick={moneyDialog.openDialog}
              snap={true}
            >
              <CardImage imageData={moneyImg} />
            </Card>
          </Grid>
        </Container>

        <div className={styles.bottomContainer}>
          <BottomCta />
          <Footer />
        </div>

        <ContentfulDialog
          visible={dialogVisible}
          onClose={closeDialog}
          contentfulData={dialogContent}
          headerStyle={{ objectPosition: '50% 0%' }}
        />
        <ContentfulDialog
          visible={cashbackDlg.isOpen}
          onClose={cashbackDlg.closeDialog}
          contentfulData={dialogContent2}
        />
        <ContentfulDialog
          visible={touchDialog.isOpen}
          onClose={touchDialog.closeDialog}
          contentfulData={touchDialogData}
        />
        <ContentfulDialog
          visible={splitDialog.isOpen}
          onClose={splitDialog.closeDialog}
          contentfulData={splitDialogData}
        />
        <ContentfulDialog
          visible={moneyDialog.isOpen}
          onClose={moneyDialog.closeDialog}
          contentfulData={moneyDialogData}
        />
        <StickyFooter />
      </Layout>
    </SnapScrollContainer>
  );
}

export default Betala;

export const query = graphql`
  query Betala($locale: String) {
    contentfulPage(pageUrl: { eq: "/betala" }, node_locale: { eq: $locale }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      heroImage {
        title
        gatsbyImageData(width: 1040)
      }
      pageContent {
        ... on ContentfulCardsSectionComponent {
          id
          entryTitle
          card {
            title
            subtitle
            linkPath
            linkText
          }
        }
        ... on ContentfulTextList {
          listName
          advancedTextListItems {
            icon
            text
          }
        }
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulDialogContent {
          id
          name
          textContent {
            textContent
          }
          questions {
            answer {
              answer
            }
            question
          }
          heading
          headerImage {
            title
            gatsbyImageData(width: 630)
          }
          ctaHref
          ctaText
          extraContent {
            ... on ContentfulTextList {
              id
              listName
              advancedTextListItems {
                icon
                text
              }
            }
            ... on ContentfulFaqSection {
              id
              questions {
                answer {
                  answer
                }
                question
              }
              title
              contactEmail
            }
          }
        }
      }
    }
  }
`;
